<template>
<b-col cols="12">
      <b-card>
        <b-card-header>
          <b-card-title>Menciones de  industrias/categorías</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col class="p-2" lg="3" md="4" sm="6" v-for="item in this.mentions.slice(0,max)" :key="item.id">

                  <b-card-text>{{item.title}}</b-card-text>
                  <b-card-text>Menciones: {{item.count}}</b-card-text>
                  <b-progress max="100">
                    <b-progress-bar
                      :value="item.count * 10"
                      :variant="getColor(item.count)"
                    />
                  </b-progress>
            </b-col>
          </b-row>
          <div v-if="max === 8" class="text-center">
            <b-collapse id="more-interests" v-model="visible">
              <b-row>
                <b-col class="p-2" lg="3" md="4" sm="6" v-for="item in this.audienceData.interests.slice(max,mentions.length)" :key="item.id">
                  <b-card-text>{{item.title}}</b-card-text>
                  <b-card-text>Menciones: {{item.count}}</b-card-text>
                      <b-progress max="100">
                        <b-progress-bar
                          :value="item.count * 10"
                          :variant="getColor(item.count)"
                        />
                      </b-progress>
                </b-col>
              </b-row>
            </b-collapse>
            <b-button
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="more-interests"
            @click="visible = !visible" variant="outline-primary">
              {{visible ? 'Ver menos' : 'Ver más'}}
            </b-button>
          </div>
        </b-card-body>
      </b-card>
  </b-col>
</template>
<script>
import {
  BCard, BCardHeader, BCardText, BCardTitle, BCardBody, BRow, BCol, BProgressBar, BProgress
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BProgressBar,
    BProgress
  },
  props: {
    youtubeData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    industries() {
      const obj = []
      const keys = Object.keys(this.youtubeData.industries)
      for (let index = 0; index < keys.length; index++) {
        obj.push(this.youtubeData.industries[keys[index]])
      }
      return obj
    },
    categories() {
      const obj = []
      const keys = Object.keys(this.youtubeData.categories)
      for (let index = 0; index < keys.length; index++) {
        obj.push(this.youtubeData.categories[keys[index]])
      }
      return obj
    },
    mentions() {
      const merge = this.industries.concat(this.categories)
      return merge
    },
    max() {
      if (this.mentions.length >= 8) return 8
      return this.mentions.length
    }
  },
  methods:{
    getColor(val) {
      if (val >= 10) return 'success'
      if (val >= 5) return 'info'
      if (val >= 3) return 'warning'
      return 'danger'
    }
  },
}
</script>
